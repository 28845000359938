import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import { useEffect } from "react";
import useSWRMutation from "swr/mutation";

const putUpdateUserRequest = async (
  url: string,
  { arg }: { arg: { userId: string; photoUrl: string; telegramUserName: string } },
) => {
  return romanApi.patch(`${url}/${arg.userId}`, {
    photoUrl: arg.photoUrl,
    telegramUserName: arg.telegramUserName,
  });
};

export const useUpdateUser = (handleError: (err: RomanApiError) => void) => {
  const { isMutating, trigger, error } = useSWRMutation("/user", putUpdateUserRequest);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);
  return {
    error: error,
    isMutating,
    trigger,
  };
};
